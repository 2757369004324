export var Features;
(function(Features) {
    Features["FullStory"] = "fullstory";
    Features["ExpDealsLink"] = "exp-header-link";
    Features["ExpHPHeroSwap"] = "exp-hp-heroswap2";
    Features["ExpPayLaterV4"] = "exp-pay-later-v4";
    Features["ExpLocationPDP"] = "exp-location-pdp";
    Features["ExpDeductiblesV3"] = "exp-deductibles-v3";
    Features["ExpReducedContent"] = "exp-reduced-content";
    Features["HideDownPayment"] = "feat-hide-downpayment";
    Features["ExpCombinedPayment"] = "exp-combined-payment";
    Features["ExpPopularFilters"] = "exp-popular-filters";
    Features["ExperimentPCPDropdown"] = "exp-pcpdropdown2";
    Features["ExpDownPaymentOptions"] = "exp-downpayment-options2";
    Features["ExpFakedoorDownpayment"] = "exp-fakedoor-downpayment";
    Features["ExpPDPCarInfo"] = "exp-pdp-carinfo";
    Features["ExperimentPCPNoTogglePrices"] = "exp-pcp-no-toggle-prices";
    Features["ExpPCPMultipleSteps"] = "exp-pcp-multiplesteps";
})(Features || (Features = {}));
// move the removed experiments here to clean up the cookie
export var RemovedExperiments = [
    "exp-plpdrawer2",
    "exp-hp-heroswap",
    "exp-pcpdropdown2",
    "exp-plpdrawer",
    "feat-productapi-getsinglecar",
    "feat-productapi-getcars",
    "feat-productapi-getfilters",
    "feat-downpayment",
    "exp-pdpctatext-v2",
    "exp-recommended-label-v2",
    "exp-downpayment2",
    "exp-productcard-v3",
    "exp-pcpdropdown",
    "exp-plp-education2",
    "exp-pcpnextbutton",
    "exp-pcppaymentinfo",
    "exp-pcpbuttons",
    "exp-plp-search",
    "exp-plp-productcard-v3",
    "exp-trustpilot2",
    "exp-trustpilot",
    "exp-deductibles",
    "exp-campaignbanners",
    "exp-plp-productcard-v1",
    "exp-plp-productcard-v2",
    "exp-wishlist",
    "exp-minib2b-lp-2",
    "exp-delivery-helper",
    "exp-header-link",
    "exp-combined-payment",
    "exp-business-filter",
    "exp-pay-later-v3",
    "exp-plp-grid-v1", 
];
